<template>
  <!-- 移动端导航 -->
  <div class="animate__animated animate__bounce mobile">
    <div class="navbar">
      <input type="checkbox" />
      <span></span>
      <span></span>
      <ul>
        <li>
          <router-link class="animsition-link" to="/home">首页</router-link>
        </li>
        <li>
          <router-link class="animsition-link" to="essay">随笔</router-link>
        </li>
        <li>
          <router-link class="animsition-link" to="/leave">留言</router-link>
        </li>
        <li>
          <router-link class="animsition-link" to="/about">关于</router-link>
        </li>
        <li>
          <router-link class="animsition-link" to="/login">登陆</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileNav",
  methods: {
    toLogin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/nav/nav-mobile.css" screen and (max-width: 720px) and
  (min-width: 300px);
</style>