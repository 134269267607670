<template>
  <div class="aurora-bubble" :style="getBubbleStyle">
    <div class="aurora-bubble-box" id="aurora-bubble-box"></div>
    <canvas class="aurora-bubble-canvas" id="aurora-bubble-canvas"></canvas>
  </div>
</template>

<script>
//获取用户传入的配置
let bubbleNumber = 0.15; // 气泡数量
let bubbleAlpha = 0.7; //气泡透明度
let alphaChangeSpeed = 0; //气泡透明度变化速度
let size = 0.5; //气泡大小
let sizeChangeSpeed = 0; //气泡大小变化速度
let riseSpeed = 0.3; //气泡上升速度
let color = "255,255,255";
let zIndex = -1;

export default {
  name: "AuroraBubble",
  computed: {
    getBubbleStyle() {
      return "z-index: " + zIndex + ";";
    },
  },
  mounted() {
    import("@/common/bubble.js").then((module) => {
      module.bubble(
        bubbleNumber,
        bubbleAlpha,
        alphaChangeSpeed,
        size,
        sizeChangeSpeed,
        riseSpeed,
        color
      );
    });
  },
};
</script>

<style scoped>
.aurora-bubble {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  /* background-color: blueviolet; */
}

.aurora-bubble-box,
.aurora-bubble-canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
</style>