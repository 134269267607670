export default {
  Top: [
    {
      lIcon: '#icon-huanyingye',
      link: '/',
      title: 'Logo',
      rIcon: '#icon-a-dakubeishangshangxinbiaoqingxiaolian'
    },
    {
      lIcon: '#icon-icon_shouye',
      link: '/home',
      title: '首页',
      rIcon: '#icon-a-keshuishuijuebiaoqingxiaolian'
    },
    {
      lIcon: '#icon-wenzhang',
      link: '/essay',
      title: '随笔',
      rIcon: '#icon-a-keaiyouqudeganxingqubiaoqingxiaolian'
    },
    {
      lIcon: '#icon-liuyan',
      link: '/leave',
      title: '留言',
      rIcon: '#icon-a-taibanglejingyabangbangdebiaoqingxiaolian'
    },
    {
      lIcon: '#icon-pengyouquan',
      link: '/friends',
      title: '后宫',
      rIcon: '#icon-a-kuzhuangkushuaiqishuashuaibiaoqingxiaolian'
    },
    {
      lIcon: '#icon-guanyu',
      link: '/about',
      title: '关于',
      rIcon: '#icon-a-aixihuanliukoushuisebiaoqingxiaolian'
    },
    {
      lIcon: '#icon-aiqingniao',
      link: '/li-ang',
      title: 'Wish',
      rIcon: '#icon-a-aiqingxihuansebiaoqingxiaolian'
    },

  ],
  bottom: [
    {
      lIcon: '#icon-a-teddywanjuxiong',
      link: '#',
      title: '搜索',
      rIcon: '#icon-a-bucuohaochiweidaobiaoqingxiaolian'
    },
    {
      lIcon: '#icon-wanjuxing',
      link: '/timeline',
      title: '热点',
      rIcon: '#icon-a-dakubeishangshangxinbiaoqingxiaolian'
    },
    {
      lIcon: '#icon-wanju',
      link: '/manage/home',
      title: '后台',
      rIcon: '#icon-a-keaiyouqudeganxingqubiaoqingxiaolian'
    },
  ]
}
