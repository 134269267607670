import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userMsg: {},
    isPc: true,
    showWave: false,
  },
  getters: {
    getUserMsg(state) {
      let msg = JSON.parse(localStorage.getItem('user_msg'));
      if (msg) {
        state.userMsg = msg;
      }
      return state.userMsg;
    },
    isPc(state) {
      return state.isPc;
    },
  },

  mutations: {
    setUserMsg(state, user_msg) {
      state.userMsg = user_msg;
      localStorage.setItem('user_msg', JSON.stringify(user_msg));
    },
    delUserMsg(state) {
      state.userMsg = {};
    },
    setNavBar(state) {
      state.isPc = !state.isPc;
    },
    showWave(state) {
      state.showWave = !state.showWave;
    }
  },
  actions: {
  },
  modules: {
  }
})
