<template>
  <div id="app">
    <NavBar v-if="$route.meta.showNav"></NavBar>
    <router-view />
    <AuroraBubble></AuroraBubble>
    <BackTop></BackTop>
  </div>
</template>

<script>
  import NavBar from "views/nav/NavBar.vue";
  import AuroraBubble from "components/common/bubble/Bubble.vue";
  import BackTop from "components/common/backTop/BackTop.vue";

  export default {
    name: "APP",
    components: {
      NavBar,
      AuroraBubble,
      BackTop,
    },
  };
</script>

<style>
  @import url("assets/css/base/base.css");
  @import url("assets/css/base/scrollbar.css");
  @import url("assets/css/base/pc.css");
  @import url("assets/css/base/mobile.css");
</style>
