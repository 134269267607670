import Vue from 'vue'
import VueRouter from 'vue-router'
import Tips from "common/tips.js";
// 前台
const Welcome = () => import('views/welcome/Welcome');
const Login = () => import('views/auth/Login');
const QQLogin = () => import('views/auth/QQLogin');
const Detail = () => import('views/detail/Detail');
const Home = () => import('views/home/Home');
const Article = () => import('views/article/Article');
const Essay = () => import('views/essay/Essay');
const Leave = () => import('views/leave/Leave');
const About = () => import('views/about/About');
const Friends = () => import('views/friends/Friends.vue');
const MyLove = () => import('views/my-love/MyLove.vue');
const TimeLine = () => import('views/timeline/TimeLine.vue');
// 后台
const Manage = () => import('views/manage/Manage.vue');
const ManageHome = () => import('views/manage/children/Home.vue');
const UserManage = () => import('views/manage/children/Users.vue');
const OAuthManage = () => import('views/manage/children/Oauth.vue');
const Anno = () => import('views/manage/children/Anno.vue');
const EssayManage = () => import('views/manage/children/Essay.vue');
const FriendsManage = () => import('views/manage/children/Friends.vue');
const AddArticle = () => import('views/manage/children/AddArticle.vue');
const ArticleManage = () => import('views/manage/children/ArticleManage.vue');
const TagsManage = () => import('views/manage/children/TagsManage.vue');
const CateManage = () => import('views/manage/children/CateManage.vue');
const CommentsManage = () => import('views/manage/children/CommentsManage.vue');
const LeaveMsgManage = () => import('views/manage/children/LeaveMsgManage.vue');

Vue.use(VueRouter)

const routes = [
  // blog
  {
    path: '/',
    component: Welcome,
    meta: {
      title: '“不晚”的加油站',
      showNav: false
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: '快上车~',
      showNav: false
    }
  },
  {
    path: '/oauth/qq/callback',
    component: QQLogin,
    meta: {
      title: 'QQ登陆中...',
      showNav: false
    }
  },
  {
    path: '/detail',
    name: 'detail',
    component: Detail,
    meta: {
      title: '我的',
      showNav: true
    }
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      title: '“不晚”的加油站',
      showNav: true
    }
  },
  {
    path: '/article/:id',
    name: 'article',
    component: Article,
    meta: {
      title: '加油ing...',
      showNav: true
    }
  },
  {
    path: '/essay',
    name: 'essay',
    component: Essay,
    meta: {
      title: '"不晚"的说说',
      showNav: true
    }
  },
  {
    path: '/leave',
    name: 'leave',
    component: Leave,
    meta: {
      title: '"不晚"的留言',
      showNav: true
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      title: '"不晚"的简历',
      showNav: true
    }
  },
  {
    path: '/friends',
    name: 'friends',
    component: Friends,
    meta: {
      title: '"不晚"的朋友圈',
      showNav: true
    }
  },
  {
    path: '/li-ang',
    name: 'li-ang',
    component: MyLove,
    meta: {
      title: '"不晚"💕"黎昂"~',
      requireAuth: true,
      showNav: true
    },
  },
  {
    path: '/timeline',
    name: 'timeline',
    component: TimeLine,
    meta: {
      title: '热点事件',
      showNav: true
    },
  },
  {
    path: '/manage/',
    name: 'manage',
    component: Manage,
    meta: {
      title: '后台管理',
    },
    children: [
      // 首页
      {
        path: 'home',
        component: ManageHome,
        meta: {
          title: '站内大数据',
          superUser: true,
        }
      },
      // 用户管理
      {
        path: 'users',
        component: UserManage,
        meta: {
          title: '所有用户',
          superUser: true
        }
      },
      // 第三方用户管理
      {
        path: 'oauth',
        component: OAuthManage,
        meta: {
          title: '第三方平台用户',
          superUser: true
        }
      },
      // 网站公告
      {
        path: 'anno',
        component: Anno,
        meta: {
          title: '网站公告',
          superUser: true
        }
      },
      // 说说管理
      {
        path: 'lives',
        component: EssayManage,
        meta: {
          title: '说说管理',
          superUser: true,
        }
      },
      // 朋友圈管理
      {
        path: 'friends',
        component: FriendsManage,
        meta: {
          title: '友链管理',
          superUser: true,
        }
      },
      // 文章管理
      {
        path: 'add-article',
        component: AddArticle,
        meta: {
          title: '添加文章',
          superUser: true
        }
      },
      {
        path: 'article',
        component: ArticleManage,
        meta: {
          title: '文章管理',
          superUser: true
        }
      },
      {
        path: 'article-tags',
        component: TagsManage,
        meta: {
          title: '标签管理',
          superUser: true
        }
      },
      {
        path: 'article-cates',
        component: CateManage,
        meta: {
          title: '分类管理',
          superUser: true
        }
      },
      {
        path: 'comments',
        component: CommentsManage,
        meta: {
          title: "文章评论管理",
          superUser: true
        }
      },
      {
        path: 'leaveMsg',
        component: LeaveMsgManage,
        meta: {
          title: "留言管理",
          superUser: true
        }
      }
    ]
  }
  // other service
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  // 清除页面之间同步滚动的影响
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  let userObj = JSON.parse(localStorage.getItem('user_msg'));
  let user_type;
  if (userObj) {
    user_type = userObj.user_type;
  } else {
    user_type = undefined;
  }
  // 路由跳转前检查权限
  // 需要登陆权限
  if (to.meta.requireAuth) {
    if (userObj) {
      next();
      document.title = to.meta.title;
    } else {
      Tips({ title: '禁止访问！', infoTips: '您还没有登陆哦！' });
    }
    // 超级用户权限
  } else if (to.meta.superUser) {
    if (!userObj) {
      Tips({ title: '禁止访问！', infoTips: '请登陆管理员帐号！' });
    }
    if (user_type && user_type === 1) {
      next();
      document.title = to.meta.title;
    } else {
      Tips({ title: '禁止访问！', infoTips: '您不是管理员哦！' });
    }
    // 直接放行
  } else if (!to.meta.requireAuth || !to.meta.superUser) {
    next();
    document.title = to.meta.title;
  }
})

export default router
