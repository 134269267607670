<template>
  <div class="nav-bar">
    <!-- pc端导航栏 -->
    <PcNav v-show="showNav"></PcNav>
    <!-- 移动端导航栏 -->
    <MobileNav v-show="showNav"></MobileNav>
  </div>
</template>

<script>
import PcNav from "./pc/PcNav.vue";
import MobileNav from "./mobile/MobileNav.vue";
export default {
  name: "NavBar",
  components: {
    PcNav,
    MobileNav,
  },
  data() {
    return {
      screenWidth: 0,
    };
  },
  computed: {
    showNav() {
      return this.$store.getters.isPc;
    },
  },
};
</script>
<style scoped>
.nav-bar {
  position: relative;
  z-index: 1;
}
</style>