<template>
  <!-- pc端导航 -->
  <!-- <div class="animate__animated animate__fadeInLeft wrap"> -->
  <div class="wrap">
    <div class="nav">
      <!-- 三个点点 -->
      <div class="btn">
        <div class="btn-item"></div>
        <div class="btn-item"></div>
        <div class="btn-item"></div>
      </div>
      <!-- 头像和标题 -->
      <div class="icon-a">
        <div class="icon-img">
          <img v-if="userMsg.avatar" :src="userMsg.avatar" />
          <img v-else src="@/assets/image/avatar/default_avatar.jpg" />
        </div>
        <div class="icon-con">
          <p>欢迎光临😁</p>
          <!-- <h4 v-if="userMsg.name" class="name-h2">
            <router-link class="login" to="/detail">{{
              userMsg.name
            }}</router-link>
          </h4> -->
          <h4 v-if="userMsg.name" class="name-h2">
            <span class="login" @click="logout">{{
              userMsg.name
            }}</span>
          </h4>
          <h2 v-else class="name-h2">
            <router-link class="login" to="/login">去登陆吧~</router-link>
          </h2>
        </div>
      </div>
      <!-- 第一个分割线和标题 -->
      <div class="line"></div>
      <div class="title">
        <p>💕Menu</p>
      </div>
      <!-- 上面的菜单内容 -->
      <div class="menu">
        <div class="menu-box">
          <!-- 每一个菜单 -->
          <div :class="{ active: item.link === $route.path, item }" v-for="(item, index) in Nav.Top">
            <div class="licon">
              <svg class="icon" aria-hidden="true">
                <use :xlink:href="item.lIcon"></use>
              </svg>
            </div>
            <div class="con">
              <router-link :to="item.link">{{ item.title }}</router-link>
            </div>
            <div class="ricon">
              <svg class="icon" aria-hidden="true">
                <use :xlink:href="item.rIcon"></use>
              </svg>
            </div>
          </div>
          <div class="light"></div>
          <!-- <div class="top-item-hover" :style="{ top: position }"></div> -->
        </div>
      </div>
      <!-- 第二个分割线和标题 -->
      <div class="line"></div>
      <div class="title">
        <p>💕Serve</p>
      </div>
      <!-- 下面的菜单内容 -->
      <div class="menu">
        <!-- 菜单内容 -->
        <div class="item" v-for="(item, index) in Nav.bottom">
          <div class="licon">
            <svg class="icon" aria-hidden="true">
              <use :xlink:href="item.lIcon"></use>
            </svg>
          </div>
          <div class="con">
            <router-link :to="item.link">{{ item.title }}</router-link>
          </div>
          <div class="ricon">
            <svg class="icon" aria-hidden="true">
              <use :xlink:href="item.rIcon"></use>
            </svg>
          </div>
        </div>
      </div>
      <!-- 日夜切换 -->
      <div class="sunmoon">
        <el-switch v-model="lightThem" active-color="#ffeea2" inactive-color="#040720" active-text="日升"
          inactive-text="月落">
        </el-switch>
      </div>
    </div>
    <SunMoon :showMe.sync=themChange :themClassName.sync=themClassName></SunMoon>
  </div>
</template>

<script>
import topNav from "common/nav.js";
import SunMoon from "@/components/common/sunmoon/sunmoon.vue";
export default {
  name: "PcNav",
  components: {
    SunMoon
  },
  data() {
    return {
      Nav: topNav,
      lightThem: true,  // 控制白天还是夜晚
      themChange: false,  // 控制是否显示出效果
    };
  },
  methods: {
    // 退出登陆
    logout() {
      this.$confirm("不要离开好不好？😢", "朋友：", {
        confirmButtonText: "狠心离开",
        cancelButtonText: "算了",
        type: "warning",
      })
        .then(() => {
          this.$store.commit("delUserMsg");
          localStorage.removeItem("user_msg");
          this.$router.push("/home");
        })
        .catch(() => {
          this.$message.warning("好的呢！爱你哟😘");
        });
    }
  },
  computed: {
    userMsg() {
      return this.$store.getters.getUserMsg;
    },
    themClassName() {
      return this.lightThem ? 'light' : 'dark';
    }
  }
};
</script>

<style scoped>
@import "@/assets/css/nav/nav-pc.css" screen and (max-width: 3000px) and (min-width: 720px);
</style>