/**
var obj = {
  title:"提示框",	//标题 可选 也是默认值
  infoTips:"hi",	//提示消息 可选 也是默认值
  duration:800,	//消失时间 可选 也是默认值
  width:180,		//宽度 可选 也是默认值
  height:100,		//高度 可选 也是默认值
};
showTips(obj);
 */

export default function showTips(objInfo) {
  var mTitle = objInfo && objInfo.title || "Tips";//标题 可选 也是默认值
  var w = objInfo && objInfo.width || 280;//宽度 可选 也是默认值

  var h = objInfo && objInfo.height || 100;//高度 可选 也是默认值
  var duration = objInfo && objInfo.duration || 2000;//消失时间 可选 也是默认值

  var infoTips = objInfo && objInfo.infoTips || "hi~";//提示消息 可选 也是默认值

  var alert = document.createElement("div");
  alert.className = "animate__animated animate__shakeX";
  alert.style.cssText = `
    position:fixed;
    left: calc(50% - 140px);
    top: 46%;
    z-index:99999;
    transform: translate(-50%,-50%);
    color:red;
    border: 3px solid skyblue;
    font-size:18px;
    border-radius:10px;
    box-shadow:inset 0px 0px 8px #fff;
    background-color: #f5f5f5;
    overflow:hidden;
  `;
  alert.style.width = w + "px";
  alert.style.minHeight = h + "px";

  var title = document.createElement("p");
  title.innerHTML = mTitle + ":";
  title.style.cssText = `margin:10px 0 10px 10px;`;

  var con = document.createElement("p");
  con.style.cssText = `
    display:flex;
    justify-content:center;
    align-items:center;
    padding:0px 10px 0px;
    margin-bottom:10px;
    font-size:16px;
    word-break:break-all;
  `;
  con.innerHTML = infoTips;

  alert.appendChild(title);
  alert.appendChild(con);
  document.body.appendChild(alert);
  let timer = setTimeout(function () {
    document.body.removeChild(alert);
    clearTimeout(timer);
  }, duration);
}
