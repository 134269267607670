<template>
  <div class="sunmoon" v-show="showMe">
    <div :class="{dark: darkClassName, light: lightClassName}" id="container">
      <div class="bg"></div>
      <div class="moon-box">
        <div class="moon"></div>
      </div>

      <div class="sun-box">
        <div class="sun"></div>
      </div>
      <div class="sea"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SunMoon",
  props: {
    showMe: {
      type: Boolean,
      default: () => false,
    },
    themClassName: {
      type: String,
      default: () => 'light'
    }
  },
  computed: {
    lightClassName() {
      return this.themClassName === 'light' ? true : false;
    },
    darkClassName() {
      return this.themClassName === 'dark' ? true : false;
    }
  }
}
</script>

<style scoped>
#container {
  /* 百分百窗口高度 */
  height: 100vh;
  width: 100vw;
  /* position: relative;
  left: 0;
  top: 0;
  z-index: 1000; */
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.sun {
  margin: 0;
  padding: 0;
  /* 绝对定位，水平垂直居中 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 600px;
  background-color: #ffa500;
  border-radius: 50%;
}
.moon {
  margin: 0;
  padding: 0;
  /* 绝对定位，水平垂直居中 */
  position: absolute;
  top: 50%;
  left: 50%;
  /* 计算出月亮位置 */
  transform: translate(calc(-50% + -160px), calc(-50% + -180px));
  width: 600px;
  height: 600px;
  /* 通过阴影绘制月亮 */
  box-shadow: 160px 180px 0 #00ffff;
  border-radius: 50%;
}
.sea {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 35%;
  /* 背景模糊 制造大海的感觉 */
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
  z-index: 100;
}
.sun,
.moon,
.sun-box,
.moon-box,
.bg {
  /* 添加动画过度 */
  transition: all .1s ease-in-out;
}
.sun-box,
.moon-box {
  position: relative;
  overflow: hidden;
}
/* 白天 */
.light .sun-box {
  height: 100vh;
}
.light .moon-box {
  height: 0;
}
.light .bg {
  background-color: #ffeea2;
}
/* 夜晚 */
.dark .sun-box {
  height: 0;
}
.dark .moon-box {
  height: 100vh;
}
.dark .bg {
  background-color: #040720;
}
</style>